import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, styled } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import useSettings from "src/hooks/useSettings";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapperMin: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
  },
  wrapperMax: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  },
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    overflow: "auto",
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-256px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
  })
);

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [isNavOpen, setNavOpen] = useState(true);

  const { settings } = useSettings();
  const expandFalse = settings.expand === false;

  const classWrapper = expandFalse ? classes.wrapperMax : classes.wrapperMin;

  return (
    <div className={classes.root}>
      {expandFalse ? (
        false
      ) : (
        <>
          <TopBar onNavHandle={() => setNavOpen(!isNavOpen)} />
          <NavBar onClose={() => setNavOpen(false)} open={isNavOpen} />
        </>
      )}
      <div className={classWrapper}>
        <Main open={isNavOpen}>{children}</Main>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
