import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import axios from "src/utils/axios";
import moment from "moment";

const initialState = {
  events: [],
  isModalOpen: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    getEvents(state, action) {
      const { events } = action.payload;

      state.events = events;
    },
    createEvent(state, action) {
      const { event } = action.payload;

      state.events = [...state.events, event];
    },
    selectEvent(state, action) {
      const { eventId = null } = action.payload;

      state.isModalOpen = true;
      state.selectedEventId = eventId;
    },
    updateEvent(state, action) {
      const { event } = action.payload;

      state.events = _.map(state.events, (_event) => {
        if (_event.id === event.id) {
          return event;
        }

        return _event;
      });
    },
    deleteEvent(state, action) {
      const { eventId } = action.payload;

      state.events = _.reject(state.events, { id: eventId });
    },
    selectRange(state, action) {
      const { start, end } = action.payload;

      state.isModalOpen = true;
      state.selectedRange = {
        start,
        end,
      };
    },
    openModal(state) {
      state.isModalOpen = true;
    },
    closeModal(state) {
      state.isModalOpen = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
  },
});

const formatDate = (pd, dd, start, end, event, type) => {
  switch (type) {
    case "pickupDate":
      if (pd && start && dd && !end)
        return moment(event === "R" ? start : pd).format("DD/MM/YYYY HH:mm:ss");
      if (pd && start) return moment(start).format("DD/MM/YYYY HH:mm:ss");
      if (!pd && start && !dd)
        return moment(start).format("DD/MM/YYYY HH:mm:ss");
      return null;
    case "deliveryDate":
      if (pd && start && dd && !end)
        return moment(event === "E" ? start : dd).format("DD/MM/YYYY HH:mm:ss");
      if (dd && end) return moment(end).format("DD/MM/YYYY HH:mm:ss");
      if (!dd && end) return moment(end).format("DD/MM/YYYY HH:mm:ss");
      if (dd && start && !pd)
        return moment(start).format("DD/MM/YYYY HH:mm:ss");
      return null;
    default:
      return null;
  }
};

const typeEvent = (title) => {
  return title ? title.trim()[0] : null;
};

export const reducer = slice.reducer;

export const getEvents = (params) => async (dispatch) => {
  const { dates, idPlaceOrigin } = params;
  const response = await axios.post("transport_scheduling_calendar", {
    idTransportScheduling: "",
    idPlaceOrigin,
    dateStart: dates.dateStart,
    dateEnd: dates.dateEnd,
  });
  dispatch(slice.actions.getEvents({ events: response.data }));
};

export const createEvent = (data, params) => async (dispatch) => {
  await axios.post("transport_scheduling", {
    ...data,
    pickupDate: formatDate(
      data.pickupDate,
      data.deliveryDate,
      data.start,
      data.end,
      typeEvent(data.title),
      "pickupDate",
    ),
    deliveryDate: formatDate(
      data.pickupDate,
      data.deliveryDate,
      data.start,
      data.end,
      typeEvent(data.title),
      "deliveryDate",
    ),
  });

  const { dates, idPlaceOrigin } = params;
  const response = await axios.post("transport_scheduling_calendar", {
    idTransportScheduling: "",
    idPlaceOrigin,
    dateStart: dates.dateStart,
    dateEnd: dates.dateEnd,
  });
  dispatch(slice.actions.getEvents({ events: response.data }));
};

export const selectEvent = (eventId) => async (dispatch) => {
  dispatch(slice.actions.selectEvent({ eventId }));
};

export const updateEvent = (eventId, update, params) => async (dispatch) => {
  const data = {
    ...update,
    idTransportScheduling: eventId,
    pickupDate: formatDate(
      update.pickupDate,
      update.deliveryDate,
      update.start,
      update.end,
      typeEvent(update.title),
      "pickupDate",
    ),
    deliveryDate: formatDate(
      update.pickupDate,
      update.deliveryDate,
      update.start,
      update.end,
      typeEvent(update.title),
      "deliveryDate",
    ),
  };
  await axios.put("transport_scheduling", data);

  const { dates, idPlaceOrigin } = params;
  const response = await axios.post("transport_scheduling_calendar", {
    idTransportScheduling: "",
    idPlaceOrigin,
    dateStart: dates.dateStart,
    dateEnd: dates.dateEnd,
  });
  dispatch(slice.actions.getEvents({ events: response.data }));
};

export const deleteEvent = (eventId, params) => async (dispatch) => {
  await axios.delete(`transport_scheduling/${eventId}`);

  const { dates, idPlaceOrigin } = params;
  const response = await axios.post("transport_scheduling_calendar", {
    idTransportScheduling: "",
    idPlaceOrigin,
    dateStart: dates.dateStart,
    dateEnd: dates.dateEnd,
  });
  dispatch(slice.actions.getEvents({ events: response.data }));
};

export const selectRange = (start, end) => (dispatch) => {
  dispatch(
    slice.actions.selectRange({
      start: start.getTime(),
      end: end.getTime(),
    }),
  );
};

export const openModal = () => (dispatch) => {
  dispatch(slice.actions.openModal());
};

export const closeModal = () => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export default slice;
