import React, { useState, useRef } from "react";
import { IconButton, SvgIcon, Tooltip } from "@material-ui/core";
import useSettings from "src/hooks/useSettings";
import { Moon, Sun } from "react-feather";

const Settings = () => {
  const ref = useRef(null);
  const { settings, saveSettings } = useSettings();
  const [values, setValues] = useState({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  const handleChangeTheme = (field, value) => {
    setValues({
      ...values,
      [field]: value,
    });
    saveSettings({
      ...values,
      [field]: value,
    });
  };

  return (
    <>
      <Tooltip title="Alterar Tema">
        {values.theme === "LIGHT" ? (
          <IconButton
            color="inherit"
            onClick={() => handleChangeTheme("theme", "ONE_DARK")}
            ref={ref}
          >
            <SvgIcon fontSize="small">
              <Moon />
            </SvgIcon>
          </IconButton>
        ) : (
          <IconButton
            color="inherit"
            onClick={() => handleChangeTheme("theme", "LIGHT")}
            ref={ref}
          >
            <SvgIcon fontSize="small">
              <Sun />
            </SvgIcon>
          </IconButton>
        )}
      </Tooltip>
    </>
  );
};

export default Settings;
