import React, { useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Button,
  Collapse,
  ListItem,
  alpha,
  makeStyles,
} from "@material-ui/core";
import { ArrowDropDown, ArrowRight } from "@material-ui/icons";
import { Minus } from "react-feather";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    padding: "5px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover": {
      backgroundColor: alpha(theme.palette.info.light, 0.2),
    },
    "&.depth-0": {
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: "5px 8px",
    margin: "2px 0",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover": {
      backgroundColor: alpha(theme.palette.info.light, 0.2),
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    minWidth: 25,
    marginRight: theme.spacing(1),
    color: theme.palette.info.main,
  },
  title: {
    marginRight: "auto",
    textAlign: "start",
  },
  active: {
    backgroundColor: alpha(theme.palette.info.light, 0.2),
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const NavItem = ({
  children,
  className,
  depth,
  href,
  icon: Icon,
  info: Info,
  open: openProp,
  title,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 16 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          className={clsx(classes.button, `depth-${depth}`)}
          onClick={handleToggle}
          style={style}
        >
          {Icon ? (
            <Icon className={classes.icon} size="20" />
          ) : (
            <Minus className={classes.icon} size="20" />
          )}
          <span className={classes.title}>{title}</span>
          {open ? <ArrowDropDown /> : <ArrowRight />}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
    >
      <Button
        activeClassName={classes.active}
        className={clsx(classes.buttonLeaf, `depth-${depth}`)}
        component={RouterLink}
        exact
        style={style}
        to={href}
      >
        {Icon ? (
          <Icon className={classes.icon} size="20" />
        ) : (
          <Minus className={classes.icon} size="20" />
        )}
        <span className={classes.title}>{title}</span>
        {Info && <Info />}
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
